import React from "react"
import classNames from "classnames"
// Gatsby
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import { MDXRenderer } from "gatsby-plugin-mdx"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import Close from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"
// core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import CardGatsbyBgImg from "components/appComponents/Card/CardGatsbyBgImg"
import CardBody from "components/appComponents/Card/CardBody.js"
import Button from "components/CustomButtons/Button.js"
import Section from "components/appComponents/Section/Section"
// style
import projectsStyle from "assets/jss/app/views/projectsStyle.js"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

Transition.displayName = "Transition"

const ProjectsSection = withStyles(projectsStyle)((props) => {
  const { classes } = props
  const [dialogOpen, setDialogOpen] = React.useState(null)

  const data = useStaticQuery(graphql`
    query {
      projects: allMdx(
        filter: { fileAbsolutePath: { regex: "/content/projects/" } }
        sort: { fields: slug }
      ) {
        nodes {
          id
          body
          frontmatter {
            category
            title
            summary
            cardSize
            cardImage {
              childImageSharp {
                gatsbyImageData(
                  quality: 20
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Section title="Our work" id="projects">
      <GridContainer>
        {data.projects.nodes.map((proj) => (
          <GridItem
            xs={12}
            sm={proj.frontmatter.cardSize}
            md={proj.frontmatter.cardSize}
          >
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault()
                setDialogOpen(proj.id)
              }}
            >
              <CardGatsbyBgImg
                image={getImage(proj.frontmatter.cardImage)}
                raised
                darken
              >
                <CardBody background>
                  <h6 className={classes.cardCategory}>
                    {proj.frontmatter.category}
                  </h6>
                  <h3 className={classes.cardTitleWhite}>
                    {proj.frontmatter.title}
                  </h3>
                  <p className={classes.cardDescription}>
                    {proj.frontmatter.summary}
                  </p>
                </CardBody>
              </CardGatsbyBgImg>
            </a>
            {/* MODAL START */}
            <Dialog
              classes={{
                root: classes.modalRoot,
                container: classes.modalContainer,
                paper: classNames(classes.modal, classes.modalLarge),
              }}
              open={proj.id === dialogOpen}
              TransitionComponent={Transition}
              keepMounted
              onClose={setDialogOpen}
              aria-labelledby={"modal-title-" + proj.id}
              aria-describedby={"modal-content-" + proj.id}
            >
              <DialogTitle
                id={"modal-title-" + proj.id}
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  simple
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  onClick={setDialogOpen}
                >
                  {" "}
                  <Close className={classes.modalClose} />
                </Button>
                <h3 className={classes.modalTitle}>{proj.frontmatter.title}</h3>
              </DialogTitle>
              <DialogContent
                id={"modal-content-" + proj.id}
                className={classes.cardContent}
              >
                <MDXRenderer>{proj.body}</MDXRenderer>
              </DialogContent>
            </Dialog>
            {/* MODAL END */}
          </GridItem>
        ))}
      </GridContainer>
    </Section>
  )
})

export default ProjectsSection
