import {
  cardTitle,
  hexToRgb,
  whiteColor,
} from "assets/jss/material-kit-pro-react.js"

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js"

const projectsSection = (theme) => ({
  ...modalStyle(theme),
  cardCategory: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.7) !important",
    marginTop: "10px",
  },
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + "  !important",
    marginTop: "10px !important",
  },
  cardDescription: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.7) !important",
  },
  cardContent: {
    ...modalStyle(theme).modalBody,
    // To obtain the same style with png (gatsby-remark-images) and gif (gatsby-remark-copy-linked-files)
    "& img": {
      height: "100%",
      width: "100%",
      margin: "0px",
      left: "0px",
      right: "0px",
      maxWidth: "none",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
})

export default projectsSection
